<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <Filters @filters="getFilters" :telecallers="telecallers" />
      <PerformEmpTable
        :telecallers_data="telecallers"
        :filters="data_filters"
      />
    </Layout>
  </template>
  
  <script>
  import Filters from "./filters";
  import Layout from "../../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
    import PerformEmpTable from "./perform-emp-table.vue";

  
  export default {
    name: "main-page",
    page: {
      title: "Telecaller Analytics",
      meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
      return {
        title: "",
        items: [],
        telecallers: [],
        data_filters: [],
      };
    },
    methods: {
      async getTelecallers() {
        try {
          const response = await this.$http.get(
            appConfig.api_base_url + "/telecallers",
            {
              params: {
                status: true,
                is_field_mr: false,
              },
            }
          );
          this.telecallers = response.data.telecallers;
        } catch (err) {
          console.log(err);
        }
      },
      getFilters(e) {
        this.data_filters = e;
      },
    },
    mounted() {
      this.getTelecallers();
    },
    components: {
    Filters,
    Layout,
    PageHeader,
    PerformEmpTable
},
  };
  </script>
  