<script>
import appConfig from "@/app.config";


export default {
  props : ['telecallers_data', "filters"],
  components: {  },
  watch : {
    telecallers_data : {
      deep : true,
      handler(v) {
        this.telecallers = v;
        this.original_telecallers = v;
        this.totalRows = this.original_telecallers.length;
        this.totalRows = this.telecallers.length;
        this.getEmployeePerformance();
      }
    },
    filters: {
      deep: true,
      handler() {
        this.getEmployeePerformance();
      },
    },
  },
  data() {
    return {
      telecallers : [],
      original_telecallers : [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5,10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "DigiMR",
      sortDesc: false,
      fields: [
        { 
            key: "username", sortable: true, label: "Digi MR" 
        },
        {
            key: "total_weekdays_calls_made",
            sortable: true,
            label: "Weekdays Worked",
        },
        {
            key: "total_weekdays_calls",
            sortable: true,
            label: "Calls made in Weekdays",
        },
        {
            key: "total_weekend_calls_made",
            sortable: true,
            label: "Weekends Worked",
        },
        {
            key: "total_weekend_calls",
            sortable: true,
            label: "Calls made in Weekends",
        },
      ],
      key : 0,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.telecallers.length;
    }
  },
  mounted() {
    // Set the initial number of items
    // this.fields = [{ key: "username", sortable: true, label: "Digi MR" }];
    // this.fields.push(
    //     {
    //     key: "total_weekdays_calls_made",
    //     sortable: true,
    //     label: "Weekdays Worked",
    //   },
    //   {
    //     key: "total_weekdays_calls",
    //     sortable: true,
    //     label: "Calls made in Weekdays",
    //   },
    //   {
    //     key: "total_weekend_calls_made",
    //     sortable: true,
    //     label: "Weekends Worked",
    //   },
    //   {
    //     key: "total_weekend_calls",
    //     sortable: true,
    //     label: "Calls made in Weekends",
    //   },
      
    // );
    
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getEmployeePerformance(){
        try{
          const response = await this.$http.get(
            appConfig.api_base_url + '/telecallers/work-days-summary?is_field_mr=false',
                {
                    params: this.filters,
                }
            );
            if(typeof this.filters.telecaller_ids != 'undefined' && this.filters.telecaller_ids.length > 0){
                this.telecallers = this.original_telecallers.filter(f => {
                    return (this.filters.telecaller_ids).includes(f.id);
                });
            }
            response.data.work_days.map((res) => {
                let index = this.telecallers.findIndex(
                    (t) => t.id === res.telecaller_id
                );
            if (isNaN(this.telecallers[index].total_weekend_calls_made)) {
                this.telecallers[index].total_weekend_calls_made = 0;
                this.telecallers[index].total_weekend_calls = 0;
                this.telecallers[index].total_weekdays_calls_made = 0;
                this.telecallers[index].total_weekdays_calls = 0;
            }
            this.telecallers[index].total_weekend_calls_made += parseInt(res.total_weekend_calls_made);
            this.telecallers[index].total_weekend_calls += parseInt(res.total_weekend_calls);
            this.telecallers[index].total_weekdays_calls_made += parseInt(res.total_weekdays_calls_made);
            this.telecallers[index].total_weekdays_calls += parseInt(res.total_weekdays_calls);
            });
            this.key+=1;
        } catch(err) {
          console.log(err);
        }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Latest Call Activity</h4>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive mb-0">
            <b-table
                :items="telecallers"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :key="key"
            >
              <template v-slot:cell(total_weekdays_calls_made)="row">
                <div v-if="row.value !== ''">{{row.item.total_weekdays_calls_made}}</div>
                <div v-else>0</div>
              </template>
              <template v-slot:cell(total_weekdays_calls)="row">
                <div v-if="row.value !== ''">{{row.item.total_weekdays_calls}}</div>
                <div v-else>0</div>
              </template>
              <template v-slot:cell(total_weekend_calls_made)="row">
                <div v-if="row.value !== ''">{{row.item.total_weekend_calls_made}}</div>
                <div v-else>0</div>
              </template>
              <template v-slot:cell(total_weekend_calls)="row">
                <div v-if="row.value !== ''">{{row.item.total_weekend_calls}}</div>
                <div v-else>0</div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>